.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background-size: contain;
  background-repeat: no-repeat;
  margin:0;
  font-size: 0;
  font-family: Montserrat,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
}

.logo h2{
  font-weight: 200;
  letter-spacing: 3px;
  text-shadow: 1px 1px 1px #0c0101d1;
  margin: 0;
  font-size: 43px;
  color: white;
  font-family: 'Patrick Hand', cursive;
  font-family: 'Anton', sans-serif;
}

.awc{
  font-family: 'Cuprum', sans-serif;
}

.logo p{
  margin: -10px auto;
  font-size: 18px;
  text-transform: capitalize;
  color: white;
  text-align: center;
  font-family: 'Patrick Hand', cursive;
}

.menu a{
  list-style: none;
  cursor: pointer;
  color: #cc84a9;
  text-shadow: 1px 1px 1px #2d272a;
  margin: 0px 20px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-decoration: none;
}

.menu a:hover{
  font-size: 16px;
  border-bottom: 1px solid orange;
}

.description h1{
  margin: 0 auto 45px auto;
  font-size: 50px;
  color: white;
  text-shadow: 1px 1px 1px black;
}

.description p{
  margin: 0 auto 65px auto;
  font-size: 21px;
  color: #ffffff;
}

.description button{
  position: relative;
  overflow: hidden;
  height: 56px;
  line-height: 56px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 34px;
  border: 1px solid #fff;
  color: white;
  text-align: center;
  background-color: transparent;
  animation: float 8s ease-in-out infinite; 
  outline: none;
}

.main-container{

}


.header{
  width: 100%;
  display:flex;
  justify-content: space-around;
  padding: 25px 100px;
  box-sizing: border-box;
  background-color: black;
}

.logo{
  margin-top: -40px;
  text-align: center;
  width: 185px;
  height: 100px;
}

.logos{
  font-size: 28px;
  color: rgb(255, 255, 255);
  z-index: 90;
}

.rosenthal{
  font-family: 'Cinzel', serif;  
}

.bpc{
  font-family: 'Kaushan Script', cursive;
}

.dayt{
  font-family: 'Courgette', cursive;
}

.main{
  opacity: .9;
  width: 100%;
  height: 640px;
  background-size: cover;
  /* background-image: url('../assets/bgn.jpg')   */
}

.description {
  height: 390px;
  padding-top: 230px;
}

.start:hover{
  cursor: pointer;
  animation: addColor;
  animation-duration: 5s;
}

.bgn{
  position: absolute;
  z-index: -1;
  filter: hue-rotate(62deg);
  width: 100vw;
}

@keyframes addColor {
  from {background-color: transparent;}
  to {background-color: #573077;}
}

.project-section{
  display: flex;
  justify-content: space-around;
  height: 500px;
  position: absolute;
  z-index: 99;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 1100px;
  margin: 139px 10% 0;
  padding:20px;
  background: #fff;
  -webkit-box-shadow: 0 0 60px rgba(0,0,0,0.2);
  box-shadow: 0 0 60px rgba(0,0,0,0.2);
}

.right{
  float: right;
  font-size: 28px;
}

.right:hover{
  color: red;
  cursor: pointer;
}

.big-project{
  background-color:#25292f;
  width: 100vw;
  height: 100vh;
  animation: 1s opacity;
  /* background-image: url('../assets/bgn.jpg'); */
}

@keyframes opacity{
0%{
  height: 50vh;
}
100%{
  height: 100vh;
}
}

.p-picture{
flex:50;
background-color: red;
margin-right: 10px; 
background-image: url('https://dab1nmslvvntp.cloudfront.net/wp-content/uploads/2017/03/1490120495Progressive_Web_App_C-01.png')

}

.p-description{
text-align: left;
flex:50;
padding: 40px;
}

.p-description h1{
font-size: 25px;
font-weight: 600;
text-transform: capitalize;
}

.p-description p{
font-size: 16px;
font-weight: 200;
}

.t-logos{
display: flex;
}

.dev-logos{
width: 35px;
margin: 10px;
}

.tech-container{
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100vw;
  margin: 80px auto 0;
  padding:88px 0px;
  text-align: center;
  background-color: #111026;
}

.tech-section h1{  
  margin-top: 94px;
  font-size: 45px;
  color: #615d5d;
  transition: all 4s;
}

.tech{
  border-right: 2px dotted lightgray;
  width: 20%;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
}

.tech i {
  color: #f75247;
  font-size: 80px;
  text-align: center;
}

.tech i:hover{
  color: #a22d65;
  cursor: pointer;
  font-size: 90px;
}

.tech p{
  font-size: 15px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.l-bgn{
  position: absolute;
  width: 50%;
  bottom:0;
  left:0;
}

.r-bgn{
  position: absolute;
  width: 50%;
  top:0;
  right:0;
}

.mobile-row{
  display: flex;
  justify-content: space-around;
  width: 80%;
  height: 400px;
  margin: auto auto 111px;
}

.website-row{
  display: flex;
  justify-content: space-around;
  width: 80%;
  height: 400px;
  margin: auto auto 111px;
}

.portfolio-row{
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin:auto;
  height: 400px;
}

.mob-pic{
  background-image: url('./assets/mobile.png');
  flex: 50;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  
}

.web-pic{
  background-image: url('./assets/laptop.png');
  flex: 50;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  
}

.portfolio-pic{
  background-position-x: center;
  background-image: url('./assets/portfolio.png');
  flex: 50;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 90;
}

.mob-desc{
  flex: 30 1;
  padding: 84px;
  box-sizing: border-box;
}

.web-desc{
  flex: 30 1;
  padding: 84px;
  box-sizing: border-box;
}

.portfolio-desc{
  flex: 30 1;
  padding: 84px;
  box-sizing: border-box;
}

.tech-title{
  color:#fff;
  font-size: 1.687rem;
}

.tech-p{
  color: rgba(203,207,230,0.65);
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.work-container{
  filter: hue-rotate(-14deg);
  background-size: cover;
  padding-top: 80px;
  background-color: #f6f6f6;
  /* background-image: url('../assets/wave.png'); */
}

.mainProject {
  width: 100%;
  background-image: url('./assets/daytrip-home-img.jpg');
  height: 500px;
  background-position-y: -175px;
  position: relative;
  cursor: pointer;
}

.mainProject h2 {
  color: #fff;
  font-size: 3rem;
}

.mainProjectText {
  position: absolute;
  bottom: 90px;
  left: 100px;
}

.mainProjectText p {
  color: #fff;
  font-size: 1rem;
  text-align: left;
}


@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

.work-container h1{  
  font-size: 45px;
  color: #4c3d54;
  transition: all 4s;
}

.projects{
  padding: 35px;
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project{
  cursor: pointer;
  position: relative;
  /* animation: float 6s ease-in-out infinite;     */
  box-shadow: 1px 1px 1px #d3d3d347;
  width: 30%;
  height: 500px;
  background-color: white;
  margin: 70px 0px;
  box-sizing: border-box;
  background-size: cover;
  background-position-y: -70px;
}

.photo{
  background-size: cover;
  border-radius: 2%;
  height: 70%;
  background-image: url('https://blennd.com/wp-content/uploads/2015/07/best-website-design-blog-4.jpg')
}

.project-text{
  padding: 20px;
  text-align: left;
  width: calc(100% - 40px);
  background: #fff;
  position: absolute;
  bottom: 0;
  height: 120px;
}

.project-text h2{
  font-size: 1.65rem;
  font-weight: 500;
  color: #4c3d54;
  text-transform: capitalize;
}

.moveDiv{
  opacity:1!important; 
}

.project-text p {
  font-size: 1rem;
  width: 60%;
  line-height: 1.5rem;
  color: #777;
  font-weight: 300;
  text-transform: capitalize;
}

.work-subtitle {
  color: #777;
  width: 80%;
  height: 50px;
  line-height: 1.4rem;
  font-size: 1.1rem;
  margin: 0 auto;
}

.project-1 {
  background-image: url('./assets/evo-lite-homepage.jpg');
}

.project-2 {
  background-image: url('./assets/viridis-homepage.jpg');
}

.project-3 {
  background-image: url('./assets/awc-homepage.jpg');
  background-position-x: -75px;
  background-position-y: -3px;
}

.fa { 
  font-size: 34px;
  color: #f75247;
  position: absolute;
  right: 30px;
  bottom: 30px;
}
