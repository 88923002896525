/* ----------------------------------------------------------------
    Fonts

    Replace your Fonts as necessary
-----------------------------------------------------------------*/


body,
small,
#primary-menu ul ul li > a,
.wp-caption,
.feature-box.fbox-center.fbox-italic p,
.skills li .progress-percent .counter,
.nav-tree ul ul a,
.font-body,
.entry-meta li,
.entry-link span,
.entry blockquote p,
.more-link,
.comment-content .comment-author span,
.button.button-desc span,
.testi-content p,
.team-title span,
.before-heading,
.wedding-head .first-name span,
.wedding-head .last-name span,
.font-secondary { font-family: 'Roboto', sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6,
#logo,
#primary-menu ul li > a,
#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title > a,
#top-search form input,
.entry-link,
.entry.entry-date-section span,
.button.button-desc,
.counter,
label,
.nav-tree li a,
.wedding-head .first-name,
.wedding-head .last-name,
.font-primary { font-family: 'Source Sans Pro', sans-serif; }


