#feed .content{
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 5px;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    color: #4d4d4d;
    background-color: #d3d3d321;
    padding: 117px 83px 80px;
}

#feed .fr{
    float: right;
}

#feed .fl{
    float: left;
}

#feed .list{
    position: relative;
    width: 30%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

/* Standard */
#feed .list_bottom{
    position: relative;
    margin-top: 25px;
}

#feed li.std{
    width: 100%;
    font-size: 12px;
    margin-bottom: 8%;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    cursor: pointer;
}

#feed li.std .ico{
    flex: 0 1 25%;
    height: 100%;
    min-width: 50px;
}

#feed .flextxt{
    flex: 1 0 auto;
    padding: 5px;
    font-size: 20px;
    text-align: left;
}

#feed li{
    cursor: pointer;
    transition: all .2s linear;
}

#feed li:hover{
   box-shadow: 0px 5px 25px #ccc;
}

#feed h2{
    font-size: 1.1em;
    font-family: semibold;
    margin-left: 5px;
    line-height: 1;
}

#feed .tag{
    display: inline-block;
    margin-left: 5px;
    font-size: .6em;
    color: white;
    padding: 1px 3px;
    font-family: medium;
    vertical-align: middle;
}

#feed h3{
    font-family: regular-italic;
    font-size: .75em;
    /* color: #00B5d1; */
    text-overflow: ellipsis;
    overflow: hidden;
}

#feed .type{
    font-size: .65em;
    font-family: semibold;
    color: white;
    padding: 5px;
    border-radius: 2px;
}

#feed .type span{
    padding: 0px 5px;
}

#feed .editorial{
    background: #FFA400;
}

#feed .learn{
    background: #00B5d1;
}




/* Variants */

#feed li.feat{
    position: relative;
    display: block;
    font-size: 1.1rem;
    margin-bottom: 10px;
    background-size: cover;
    background-position: center;
    color: white;
    font-family: semibold;
    padding: 15px;
    box-sizing: border-box;
}

#feed li.feat.b{
    width: 100%;
    padding-top: 35px;
    font-size: 20px;
}

#feed li.feat.s{
    display: inline-block;
    width: 48%;
    padding-top: 66px;
    margin-bottom: 15px;
}

#feed li.feat .txt{
    bottom: 15px;
    text-align: left;
}

#feed .feat h1{
    text-shadow: 1px 1px 1px rgba(0,0,0,.75);
    color:white;
}

/* ARTICLES */

#feed .article{
    position: relative;
    flex: 1 1 60%;
    margin-left: 74px;
    height: 100%;
    overflow: hidden;
    font-size: 1.25rem;
}

#feed .art_scroll{
    height: 100%;
    text-align: left;
}

#feed .art_menu{
    position: absolute;
    color: transparent;
    background: transparent;
    font-family: header;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    transition: all .35s ease-in;
    font-size: .65em;
}

#feed .art_menu.scrolled{
    background: white;
    color: #4d4d4d;
    box-shadow: 0px 5px 5px #ccc;
}

#feed .scroll_title{
    flex: 1 1 70%;
}

#feed .art_controls{
    flex: 1 1 25%;
    background: white;
    color: green;
}

#feed .article .type{
    margin-left: -5px;
}

#feed h4{
    font-family: medium;
    font-size: 1.25em;
    margin-bottom: 15px;
}

#feed h4 span{
    font-size: .9em;
}

#feed h5{
    font-family: semibold;
    font-size: 18px;
    margin-bottom: 5px;
}

#feed p{
    font-family: light;
    line-height: 1.4;
    letter-spacing: 0.4px;
    font-size: 16px;
    margin-bottom: 30px;
    text-align: left;
}


#feed .header{
    display: block;
    font-size: .75em;
    margin-bottom: 25px;
}

#feed u{
    font-family: light-italic;
    margin-bottom: 15px; 
}

.i_decline{
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../images/decline_chalk.jpg")
}

.i_iot1{
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../images/iot1.jpg")
}

.i_ppl{
    background-image: url("../images/groupppl.jpg")
}

.i_loom{
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../images/loom.jpg")
}
