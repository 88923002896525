/* Customs */

._blue {
  color: #00B5d1;
  fill: #00B5d1;
}

._black {
  color: #232528;
  fill: #232528;
  color: rgba(35, 37, 40, 1);
}

._orange {
  color: #FFA400;
  fill: #FFA400;
}

._red {
  color: #CA2E55;
  color: rgb(202, 46, 85);
  fill: #CA2E55;
}

._deepred {
  color: rgba(209, 59, 0, 1);
}

._grey {
  color: #C8BFC7;
  fill: #C8BFC7;
  color: rgb(200, 191, 199);
}

svg {
  width: 100%;
  height: 100%;
}

b {
  color: #00B5d1;
  color: rgb(0, 181, 209);
}

em {
  font-family: bold;
}

.hexagon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='transparent' stroke='%23C8BFC7' stroke-width='3px' width='175' height='200' viewbox='0 0 180 200' style='filter: drop-shadow(rgba(255, 255, 255, 0.5) 0px 0px 10px);'%3E%3Cg%3E%3Cpath d='M77.94228634059948 4.999999999999999Q86.60254037844386 0 95.26279441628824 4.999999999999999L164.54482671904333 45Q173.20508075688772 50 173.20508075688772 60L173.20508075688772 140Q173.20508075688772 150 164.54482671904333 155L95.26279441628824 195Q86.60254037844386 200 77.94228634059948 195L8.660254037844387 155Q0 150 0 140L0 60Q0 50 8.660254037844387 45Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

/* Scroll */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none !important;
}

/* Stocks */

.stock1 {
  background-image: url("../images/stock1.jpg");
}

.stock2 {
  background-image: url("../images/stock2.jpg");
}

.stock3 {
  background-image: url("../images/stock3.jpg");
}

.i_decline {
  /* background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/article_stock/decline_chalk.jpg") */
}

.i_iot1 {
  /* background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/article_stock/iot1.jpg") */
}

.i_ppl {
  /* background-image: url("../images/article_stock/groupppl.jpg") */
}

.i_bubble {
  /* background-image: url("../images/article_stock/bubble.jpg") */
}

.i_loom {
  /* background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/article_stock/loom.jpg" */
}

/* Resets */

textarea,
button,
input,
select {
  border: none;
}

s,
u,
i,
em,
b {
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
}

q:before,
q:after {
  content: '';
  content: none;
}

input,
button {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  margin-block-end: 0;
  margin-block-start: 0;
  font-size: 1rem;
}

/* Fonts */

@font-face {
  font-family: 'extra-light';
  src: url('../css/fonts/IBMPlexSans-ExtraLight.ttf');
}

@font-face {
  font-family: 'light';
  src: url('../css/fonts/IBMPlexSans-Light.ttf');
}

@font-face {
  font-family: 'regular';
  src: url('../css/fonts/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: 'medium';
  src: url('../css/fonts/IBMPlexSans-Medium.ttf');
}

@font-face {
  font-family: 'semibold';
  src: url('../css/fonts/IBMPlexSans-SemiBold.ttf');
}

@font-face {
  font-family: 'bold';
  src: url('../css/fonts/IBMPlexSans-Bold.ttf');
}

@font-face {
  font-family: 'regular-italic';
  src: url('../css/fonts/IBMPlexSans-Italic.ttf');
}

@font-face {
  font-family: 'light-italic';
  src: url('../css/fonts/IBMPlexSans-LightItalic.ttf');
}

@font-face {
  font-family: 'header';
  src: url('../css/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'subheader';
  src: url('../css/fonts/Poppins-Regular.ttf');
}

/* @font-face { font-family: 'header'; src:  url('../css/fonts/Roboto-Bold.ttf');} */

@font-face {
  font-family: 'logo';
  src: url('../css/fonts/Play-Regular.ttf');
}

@font-face {
  font-family: 'logo-bold';
  src: url('../css/fonts/Play-Bold.ttf');
}

/* nav Bar */

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: regular;
  color: #232528;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.logo {
  width: 180px;
  font-family: logo-bold;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 40px;
  line-height: 60px;
  cursor: pointer;
  background-image: url('../images/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 12px 0 0;
}

.logo i:first-child {
  font-size: 1em;
}

.logo i:last-child {
  font-size: .6em;
  font-family: logo;
  line-height: 20px;
  margin-top: 12px;
}

.logo i:last-child span {
  font-family: logo-bold;
}

.splash {
  position: fixed;
  width: 100%;
  line-height: 1;
  padding: 0 15px 10px !important;
  box-sizing: border-box;
  font-size: 32px;
  font-family: header;
  z-index: 99;
  background: white;
  box-shadow: none;
  color: #232528;
  transition: all .4s ease-in;
  display: flex;
  justify-content: space-between;
}

.menu {
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-family: medium;
}

.menu .item {
  margin: 22px 30px 0;
  border-right: 1px solid white;
  font-size: .6em;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all .2s linear;
}

.menu .item:last-child {
  border-right: none;
}

.item:hover {
  color: #00B5d1 !important;
}

/* Css for Splash - main page */

.content {
  margin: 0 auto;
  width: 80vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #4D4D4D;
  fill: #4D4D4D;
  padding: 80px 0 0;
}

.text {
  flex: 0 1 49%;
  text-align: left;
  padding: 50px 23px 0;
  box-sizing: border-box;
}

#splash h1 {
  font-family: header;
  font-size: 60px;
  line-height: 1;
}

#splash h2 {
  margin-top: 29px;
  font-family: light;
  font-size: 32px;
  line-height: 1.25;
}

#splash .links {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

#splash a {
  height: 100px;
  width: 100px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-family: medium;
  transition: all .2s linear;
  color: #C8BFC7;
  fill: #C8BFC7;
}

#splash a svg {
  width: 55px;
  height: 100px;
}

#splash a span {
  display: block;
  margin-top: 10px;
}

#splash a:hover {
  fill: #00B5d1;
  color: #00B5d1;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='transparent' stroke='%2300B5d1' stroke-width='3px' width='175' height='200' viewbox='0 0 180 200' style='filter: drop-shadow(rgba(255, 255, 255, 0.5) 0px 0px 10px);'%3E%3Cg%3E%3Cpath d='M77.94228634059948 4.999999999999999Q86.60254037844386 0 95.26279441628824 4.999999999999999L164.54482671904333 45Q173.20508075688772 50 173.20508075688772 60L173.20508075688772 140Q173.20508075688772 150 164.54482671904333 155L95.26279441628824 195Q86.60254037844386 200 77.94228634059948 195L8.660254037844387 155Q0 150 0 140L0 60Q0 50 8.660254037844387 45Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

#splash .dino {
  flex: 0 1 40%;
  height: 700px;
}

/* Css for About us */

.rightbox {
  margin: auto;
  width: 70%;
  height: 100%;
  color: #1c1f24;
}

.about_ttl {
  font-size: 40px;
  text-align: center;
  font-family: bold;
  padding: 5px 5px;
  margin: 87px auto 30px;
}

.about_txt {
  font-size: 22px;
  padding: 15px 20px 70px;
  line-height: 2;
  text-align: center;
}

/* Css for we believe */

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  margin: auto;
  text-align: center;
}

.layer1 {
  z-index: 1;
  /* background-image: url('../css/../ismages/p1.jpg'); */
  background-color: #807676;
  background-repeat: repeat;
  background-size: 37vw;
  filter: grayscale(100%);
  opacity: .08;
  width: 100%;
}

.layer2 {
  z-index: 2;
  color: rgba(208, 206, 201, 0.2);
  font-size: 233px;
  line-height: 1;
  font-family: logo-bold;
  opacity: .7;
  text-align: center;
}

.webelieve {
  height: 85%;
  font-family: semibold;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;
  margin-top: 7.5%;
  color: #4d4d4d;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);
}

em {
  color: #00B5d1;
  font-size: 32px;
  font-family: bold;
}

.webelieve h1 {
  font-size: 35px;
}

.about-container {
  height: 100vh;
  position: relative;
}

/* css for contact */

.contact {
  width: 80%;
  margin: 29px auto 160px;
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact_cell {
  text-align: center;
  width: 350px;
  margin-top: 0;
  font-size: 28px;
  justify-content: flex-start;
}

.contact_cell svg {
  width: 64px;
  height: 40px;
  fill: #00B5d1;
  stroke: none;
  margin: 37px auto 20px;
}

.cursor-pointer {
  cursor: pointer;
}

/* css for classes/ courses */

#courses .background {
  position: absolute;
  width: 15vw;
  min-width: 250px;
  height: 15vw;
  min-height: 250px;
  opacity: 1;
  background-size: 200px;
  background-repeat: repeat;
}

#courses .background.bottom {
  right: 0;
  bottom: 0;
}

#courses .background.bottom .pattern {
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(35, 37, 40, .85) 50%, rgba(35, 37, 40.85) 100%);
}

#courses .pattern {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(315deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(35, 37, 40, .85) 50%, rgba(35, 37, 40, .85) 100%);
  color: rgba(255, 255, 255, .1);
  font-size: 150px;
  font-family: logo-bold;
}

#courses .content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 30px 20px 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #4D4D4D;
  z-index: 3;
}

#courses .list {
  padding: 20px;
  width: 80%;
  margin: 62px auto 30px;
  /* height: 100%; */
  overflow-y: auto;
  background: white;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
}

#courses .card {
  width: 100%;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 35px;
  padding-bottom: 10px;
  background: white;
}

#courses .card_ico {
  width: 20%;
  height: 125px;
  background-color: grey;
  border-radius: 4px;
  border: 1px solid #9aa3b1;
  background-size: cover;
  background-position: center;
}

.card_ico.i1 {
  background-image: url('../images/blockchain.png');
}

.card_ico.i2 {
  background-image: url('../images/iso_2.png');
}

.card_txt {
  width: calc(100% - 165px);
  margin-left: 15px;
}

.card_txt h3 {
  font-size: 18px;
  font-family: header;
}

.card_txt i {
  float: right;
  font-size: 16px;
  font-family: medium;
}

.card_txt u {
  font-size: 16px;
  color: #00B5D1;
}

.card_txt p {
  font-size: 16px;
  margin: 10px 0;
  width: 85%;
  font-family: light;
  line-height: 1.25;
}

.card_txt button {
  position: relative;
  float: right;
  font-size: 16px;
  font-family: semibold;
  background-color: rgba(209, 59, 0, 1);
  color: white;
  padding: 3px 8px;
  border-radius: 3px;
  cursor: pointer;
}

/* css for enterprise */

#enterprise {
  margin-top: 50px;
}

#enterprise .background {
  position: relative;
  width: 100%;
  height: 500px;
  min-height: 450px;
  max-height: 800px;
  background-image: url('../images/stock4.jpg');
  opacity: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#enterprise .text {
  position: absolute;
  margin-left: 4%;
  margin-top: 8%;
  width: 650px;
  padding: 15px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .75);
}

#enterprise h1 {
  font-family: header;
  color: white;
  font-size: 48px;
}

#enterprise h1 b {
  font-size: 50px;
}

#enterprise h2 {
  font-family: medium;
  color: white;
  font-size: 28px;
  margin-top: 15px;
  line-height: 1.35;
}

#enterprise .summary {
  margin: 0 auto;
  margin-top: 66px;
  text-align: center;
  font-size: 28px;
  width: 100vw;
  background: #F9F9F9;
  padding: 20px;
  display: flex;
  justify-content: center;
}

#enterprise .services {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 3%;
}

#enterprise .service {
  width: 390px;
  background: #F9F9F9;
  padding: 30px 20px 20px 20px;
  border: 1px solid #eeeeee;
  transition: all .2s ease-in;
  text-align: center;
}

#enterprise .service:hover {
  box-shadow: 0px 5px 25px #ccc;
}

#enterprise .sico {
  height: 100px;
  border-radius: 100%;
}

#enterprise h3 {
  font-family: semibold;
  font-size: 22px;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 2%;
  text-transform: uppercase;
  text-align: center;
}

#enterprise h4 {
  font-size: 18px;
  font-family: medium;
  text-transform: uppercase;
  margin-top: 27px;
  margin-bottom: 16px;
}

#enterprise p {
  font-size: 16px;
  font-family: light;
  line-height: 1.2;
  margin-bottom: 14px;
}

#enterprise p:last-of-type {
  font-size: 16px;
}

#enterprise h5 {
  font-size: 28px;
  font-family: medium;
  margin-top: 17px;
  margin-bottom: 38px;
  line-height: 1.25;
}

#enterprise .sumtext {
  width: 600px;
  text-align: left;
}

#enterprise .sumtext p {
  font-family: light;
  font-size: 18px;
  line-height: 1.5;
}

#enterprise .sumtext p:last-of-type {
  font-size: 18px;
}

#enterprise .substock {
  height: 360px;
  margin-left: 5%;
}

#enterprise .offerings {
  position: relative;
  width: 1200px;
  max-width: 90vw;
  margin: 0 auto;
  background-size: 200px;
  background-repeat: repeat;
}

#enterprise .stitle {
  display: block;
  text-transform: uppercase;
  font-family: semibold;
  font-size: 18px;
  padding: 15px 15px 0px 15px;
  color: #4d4d4d;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  writing-mode: vertical-rl;
  text-orientation: upright;
}

#enterprise .stitle.a {
  background: rgba(255, 164, 0, .25);
}

#enterprise .stitle.b {
  background: rgba(209, 59, 0, .25);
}

#enterprise .softener {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url('../images/p1.jpg');
  background-size: 200px;
  background-repeat: repeat;
  opacity: .05;
}

#enterprise .offerings.support .softener {
  transform: scaleX(-1);
}

#enterprise .overlay {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 0px 0px;
  z-index: 3;
}

#enterprise .overlay {
  position: relative;
  height: 75px;
  /* left: 50%; */
  transform: scaleX(-50%);
}

#enterprise .offer {
  width: 300px;
  padding: 15px;
  border: 1px solid #eeeeee;
  background: white;
  opacity: 1;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

#enterprise h6 {
  font-family: regular;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 21px;
}

#enterprise .why {
  position: relative;
  width: 850px;
  max-width: 90vw;
  margin: 0 auto;
  padding: 16px;
}

#enterprise .reason {
  width: 100%;
  margin-top: 39px;
  margin-bottom: 39px;
}

#enterprise u {
  font-family: medium;
  font-size: 22px;
  color: #4d4d4d;
  margin-bottom: 1rem;
}

#enterprise u span {
  display: block;
  font-family: semibold;
}

#enterprise s {
  display: block;
  flex: 1 1 auto;
  font-size: 19px;
  font-family: regular;
  color: #4d4d4d;
  line-height: 1.2;
  margin-top: 15px;
}

#enterprise hr {
  display: block;
  width: 100vw;
  height: 10px;
  margin: 0;
  border: none;
}

#enterprise .contact_cell {
  width: auto;
  font-size: 22px;
  margin-bottom: 105px;
  text-align: center;
  display: block;
}

#enterprise .contact_cell h4 {
  margin-top: 7px;
  margin-bottom: 5px;
}

#enterprise .contact_cell p {
  margin-bottom: 5px;
}

#enterprise .contact_cell svg {
  width: 60px;
  height: 40px;
  fill: #1c1f24;
  stroke: none;
  margin: 20px 0 10px;
}

#enterprise .contact_txt {
  vertical-align: top;
}

.contact_txt h2 {
  font-size: 22px;
}

.contact_txt h3 {
  font-size: 17px;
  font-weight: 200;
  margin: 10px;
  box-sizing: border-box;
}

/* css for contact */

#contact-form {
  width: 60%;
  margin: 80px auto 0;
}

.contact-form {
  transition: opacity 0.7s ease;
}

.social-media {
  width: 100%;
  height: 200px;
  background-color: #00B5D1;
  padding: 50px;
  box-sizing: border-box;
  text-align: center;
}

.social-media h2 {
  color: white;
  margin: 0 auto 30px;
  font-size: 20px;
}

.copyright {
  color: white;
  width: 100%;
  height: 80px;
  background-color: rgb(10, 21, 22);
  text-align: center;
  padding: 34px 0 0;
  box-sizing: border-box;
}

.copyright h1 {
  font-size: 14px !important;
}

.phone-query {
  display: none;
}

.icons-container {
  text-align: center;
  width: 40%;
  margin: auto;
  justify-content: space-evenly;
  display: flex;
}

.social-media .icon {
  height: 35px;
  cursor: pointer;
}

.social-media .icon:hover {
  height: 38px;
  transition: all .1s ease-in;
}


/* css for register */

.register {
  margin:auto;
  width: 90%;
  height: 100%;
  padding: 40px 55px 55px;
  box-sizing: border-box;
}

.register .left-side {
  width: 100%;
  padding: 85px 50px 50px;
  box-sizing: border-box
}

.register .right-side {
  width: 100%;
  padding: 30px 80px;
  box-sizing: border-box;
}

.left-side h1 {
  margin: 30px 0;
  font-family: header;
  font-size: 43px;
}

.left-side h2 {
  margin: 30px 0;
  font-family: header;
  color: #00B5D1;
  font-size: 23px;
}

.left-side p {
  margin: 40px auto; 
}

.date-table {
  width: 70%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  margin: 28px auto;
  display: table;
}

table.date-table tr td, .date-table th {
  border: 2px solid #02b8d4;
  text-align: center;
  min-height: 80px;
  height: 80px;
}

.date-table th {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  color: #02b8d4;
  text-transform: uppercase;
  background: #000000;
}

@media only screen and (max-width: 991px) {
  .owl-carousel {
    display: block;
  }
  .dino {
    height: 208px !important;
  }
  .content {
    display: block;
    padding: 100px 0;
    height: auto !important;
  }
  .phone-query {
    display: block;
  }
  .web-query {
    display: none !important;
  }
  #splash h1 {
    font-family: header;
    font-size: 54px !important;
    margin: 21px auto 0 !important;
  }
  #splash h2 {
    margin-top: 27px !important;
    font-family: light;
    font-size: 23px !important;
    line-height: 1.25;
  }
  #nav {
    height: 74px
  }
  .splash a {
    height: auto;
  }
  .splash a {
    height: auto;
  }
  .rightbox {
    width: 80% !important;
  }
  .about_txt {
    font-size: 19px !important;
    padding: 15px 20px 70px !important;
  }
  .contact {
    display: block !important;
    width: 100%;
  }
  .contact_cell {
    margin-top: 48px !important;
    margin: auto;
  }
  .about_ttl {
    margin: 0 auto 30px;
  }
  .layer2 {
    font-size: 226px;
  }
  #courses .list {
    width: 92% !important;
    margin: 92px auto 30px !important;
    padding: 14px;
    box-sizing: border-box;
  }
  #courses .content {
    padding: 0 ! important;
  }
  #courses .card_ico {
    width: 30% !important;
  }
  .card_txt i {
    display: none;
  }
  .card_txt h3 {
    font-size: 16px !important;
  }
  .card_txt p {
    font-size: 13.5px !important;
  }
  .register-container {
    display: flex;
    width: 90% !important;
  }
  .card {
    display: block !important;
  }
  .card-box {
    display: flex !important
  }
  .card_txt u {
    font-size: 14px !important;
    margin: 5px auto 0;
  }
  .card_txt {
    width: calc(100% - 65px);
  }
  .web-query {
    display: none !important;
  }
  #enterprise .service {
    width: 100% !important;
    box-sizing: border-box;
  }
  #enterprise .services {
    display: block !important;
  }
  #enterprise .overlay {
    display: block !important;
    padding: 0 !important;
  }
  #enterprise s {
    font-size: 17px;
  }
  #enterprise .offer {
    width: 100% !important;
    box-sizing: border-box;
  }
  #enterprise .stitle {
    writing-mode: horizontal-tb !important;
    padding: 15px;
  }
  #enterprise .substock {
    display: none;
  }
  #enterprise .text {
    width: auto;
    margin-left: 0;
  }
  em {
    font-size: 25px;
  }
  #enterprise .summary {
    width: auto;
  }
  #enterprise h3 {
    margin: 37px auto 25px;
  }
  #enterprise .why {
    background-color: #fafafa;
  }
  .icons-container {
    width: 70%;
  }
  .phone-menu{
    width: 41px;
    height: 48px;
    margin: 10px;
    position: relative;
    cursor: pointer;
    z-index: 99;
  }
  .menu-line{
    width: 100%;
    height: 5px;
    background: #C8BFC7;
    margin-top: 7px;
    border-radius: 2px;
    transition: .2s;
  }
  .phone-menu.open .menu-line:first-child{
    position: absolute;
    transform: rotate(45deg) translateY(20px);
  }
  .phone-menu.open .menu-line:nth-child(2){
    position: absolute;
    display: none;
  }
  .phone-menu.open .menu-line:nth-child(3){
    position: absolute;
    transform: rotate(-45deg) translateX(-20px);
  }
  .menu-circle {
    border-radius: 50%;
    font-size: 28px;
    color: white !important;
    line-height: 1;
    font-family: medium;
    text-align: center;
    cursor: pointer;
    margin: 20px;
    text-shadow: 1px 1px 1px black;
    transition: all .4 ease-in;
  }
  .menu-circle:hover {
    fill: #00B5d1;
    color: #00B5d1;
  }
  .menu-circle svg {
    width: 45px;
    fill: #C8BFC7;
  }
  .menu-circle a {
    margin:5px;
    color: #00B5d1!important;
  }
  .menu-circle-list {
    position: fixed;
    right: 24px;
    width: 70%;
    color: white;
    padding: 50px;
    background-color: #313131e3;
    box-sizing: border-box;
  }
  .menu-circle {
    -webkit-animation: tracking-out-contract 0.7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: tracking-out-contract 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  .hide {
    display:none;
  }
  @-webkit-keyframes tracking-out-contract {
    0% {
      opacity: 0;
      letter-spacing: -0.5em;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-out-contract {
    0% {
      opacity: 0;
      letter-spacing: -0.5em;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  .register {
    display:block;
  }
  .register .left-side {
    width: 100%;
    padding: 50px 50px 10px;
    box-sizing: border-box
  }
  
  .register .right-side {
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
  }
}
.date-table {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .text {
    padding: 10px 0;
    margin:0;
  }
  #courses .list {
    width: 92% !important;
    margin: 92px auto 30px !important;
    padding: 14px;
    box-sizing: border-box;
  }
  #courses .content {
    padding: 0 ! important;
  }
  #courses .card_ico {
    width: 30% !important;
  }
  .card_txt i {
    display: none;
  }
  .card_txt h3 {
    font-size: 16px !important;
  }
  .card_txt p {
    font-size: 13.5px !important;
  }
  .register-container {
    display: flex;
    width: 90% !important;
  }
  .card {
    display: block !important;
  }
  .card-box {
    display: flex !important
  }
  .card_txt u {
    font-size: 14px !important;
    margin: 5px auto 0;
  }
  .card_txt {
    width: calc(100% - 65px);
  }
  .web-query {
    display: none !important;
  }
  .icons-container {
    width: 100%;
  }
  .standard-logo img {
    height: 37px;
  }
}